import React from 'react';
import { Linkedin, Globe2 } from 'lucide-react';
import Header_for_all from './Header_for_all';
import Footer_for_all from './Footer_for_all';
import { Link } from 'react-router-dom';




const bgClasses = 'bg-background text-foreground p-6 bg-black';
const textClasses = 'text-white';
const titleClasse = 'text-xl font-bold';
const linkClasses = 'text-primary';


const Footer = () => {
  return (
    <div className={bgClasses}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <FooterItem title="About PSYC" content="Passive Surveillance Yielding Control - Innovating for a safer future." />
        <FooterItem title="Contact Us" content={<ContactContent />} />
        <FooterItem title="Follow Us" content={<FollowContent />} />
      </div>
      <div className="text-muted-foreground text-center mt-4">&copy; 2024 PSYC. All rights reserved.</div>
    </div>
  );
};

const FooterItem = ({ title, content }) => {
  return (
    <div className={textClasses}>
      <h2 className={titleClasse}>{title}</h2>
      <p>{content}</p>
    </div>
  );
};

const ContactContent = () => {
  return (
    <>
      <p>
        Email:{' '}
        <a href="psycdefence@gmail.com" className={linkClasses}>
        psycdefence@gmail.com
        </a>
      </p> 
      <p>
        Phone:{' '}
        <a href="tel:+918861540565" className={linkClasses}>
          +91 88615 40655
        </a>
      </p>
    </>
  );
};

const FollowContent = () => {
  return (
    <>
      <a href="#" className={linkClasses}>
        Facebook
      </a>
      <br />
      <a href="#" className={linkClasses}>
        Twitter
      </a>
      <br />
      <a href="#" className={linkClasses}>
        LinkedIn
      </a>
    </>
  );
};


const FacultyCard = ({ name, title, department, linkedinUrl, imageSrc, isPortfolio }) => {
  return (
    <div className="relative group flex flex-col items-center justify-center">
      <div className="bg-gray-900 rounded-lg p-4 w-[410px] h-[120px] transition-all duration-300 group-hover:ring-2 group-hover:ring-red-500 group-hover:ring-offset-2 group-hover:ring-offset-black">
        <div className="flex items-center justify-between space-x-4">
          <div className="w-24 h-24 rounded-full overflow-hidden bg-gray-800">
            <img src={imageSrc} alt={name} className="w-full h-full object-cover" />
          </div>
          <div>
            <h3 className="text-white text-lg font-bold">{name}</h3>
            <p className="text-red-500 text-lg">{title}</p>
            <p className="text-white text-xs">{department}</p>
          </div>
          <a
            href={linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="opacity-0 group-hover:opacity-100 transition-opacity duration-300"
          >
            {isPortfolio ? (
              <Globe2 className="text-white group-hover:text-red-500 transition-colors duration-300" size={24} />
            ) : (
              <Linkedin className="text-white group-hover:text-red-500 transition-colors duration-300" size={24} />
            )}
          </a>
        </div>
      </div>
    </div>
  );
};

const FacultyGrid = () => {
  const teamMembers = [
    { id: 1, name: 'Yogesh V Devang', des: 'Founder & CEO', linkedinUrl: 'https://www.linkedin.com/in/yogesh-v-b11bb8259/', image: '/Videos/yogesh1.jpeg' },
    // { id: 2, name: 'Karthik B', des: 'Founder & CEO', linkedinUrl: '#', image: '/Videos/person2.jpeg' },
    // { id: 3, name: 'Tarita Shetty', des: 'Founder & COO & CFO', linkedinUrl: 'https://www.linkedin.com/in/tarita-shetty-84230b260?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app', image: '/Videos/person3.jpeg' },
    // { id: 4, name: 'SreeHarsh U', des: 'Founder & CTO', linkedinUrl: '#', image: '/Videos/sreeharsh.jpeg' },
    // { id: 5, name: 'Riddhi Rai', des: 'Founder', linkedinUrl: 'https://www.linkedin.com/in/riddhi-rai?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app', image: '/Videos/person7.jpeg' },
    { id: 6, name: 'Deepak T B', des: 'Founder & CIO', linkedinUrl: 'https://www.linkedin.com/in/deepak-tarikere-basavaraju-620983260?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app', image: '/Videos/deepak.jpg' },
  ];

  const coFounders = [
    { id: 3, name: 'Tarita Shetty', des: 'Co-Founder & COO & CFO', linkedinUrl: 'https://www.linkedin.com/in/tarita-shetty-84230b260?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app', image: '/Videos/Tarita.jpg' },
    { id: 7, name: 'GS Tejas', des: 'Co-Founder & Tech-Head', linkedinUrl: 'https://gs-tejas-hub.github.io/Demon-s-Portfolio/', image: '/Videos/gsTejas.jpg', isPortfolio: true },
    { id: 4, name: 'SreeHarsh U', des: 'Co-Founder & CTO', linkedinUrl: '#', image: '/Videos/shreeharsh.jpg' },
    { id: 2, name: 'Karthik B', des: 'Co-Founder & Marketing Lead', linkedinUrl: '#', image: '/Videos/karthik.jpg' },
    // { id: 5, name: 'Riddhi Rai', des: 'Co-Founder', linkedinUrl: 'https://www.linkedin.com/in/riddhi-rai?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app', image: '/Videos/person7.jpeg' },

  ];

  const crewMembers = [
    { id: 2, name: 'Bavana RG', designation: 'ESD', image: '/Videos/bhavana.jpg' },
    { id: 3, name: "Solomon D'Costa", designation: 'R&D Engineer', image: '/Videos/solomon.jpg' },
    // { id: 4, name: 'Brunda MN', designation: '', image: '/Videos/brunda.jpeg' },
  ];

  return (
    <div className="min-h-screen bg-fixed bg-cover bg-center" style={{ backgroundImage: `url('/Videos/header5.jpeg')` }}>
      <Header_for_all />

      <div className="flex flex-col items-center justify-center">
        <div className="p-8 text-center text-white">
          <h1 className="text-4xl font-bold mb-4">Welcome To PSYC</h1>
          <h2 className="text-2xl mb-2">Meet Our Team</h2>
          <h3 className="text-xl font-bold mt-12">Directors & Founders:</h3>
        </div>

        {/* Founders Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-20 gap-y-10 p-4">
          {teamMembers.map((member) => (
            <FacultyCard
              key={member.id}
              name={member.name}
              title={member.des}
              department=""
              linkedinUrl={member.linkedinUrl}
              imageSrc={member.image}
              isPortfolio={false}
            />
          ))}
        </div>

        {/* Co-Founders Section */}
        <div className="p-8 text-center text-white">
          <h3 className="text-xl font-bold mt-2">Co-Founders:</h3>
        </div>

        <div className="grid grid-cols-3 gap-x-20 gap-y-10 p-4">
          {coFounders.map((coFounder) => (
            <FacultyCard
              key={coFounder.id}
              name={coFounder.name}
              title={coFounder.des}
              department=""
              linkedinUrl={coFounder.linkedinUrl}
              imageSrc={coFounder.image}
              isPortfolio={coFounder.isPortfolio}
            />
          ))}
        </div>

        {/* Our Crew Section */}
        <div className="p-8 text-center text-white">
          <h3 className="text-xl font-bold mt-1">Our Crew:</h3>
        </div>

        {/* Crew Members Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-x-20 gap-y-10 p-4">
          {crewMembers.map((member) => (
            <FacultyCard
              key={member.id}
              name={member.name}
              title={member.designation}
              department=""
              linkedinUrl=""
              imageSrc={member.image}
              isPortfolio={false}
            />
          ))}
        </div>
      </div>
      <div className="relative z-10 flex items-start pt-6 justify-center min-h-auto pb-6">
          <div className="bg-black bg-opacity-100 text-white p-6 rounded-lg max-w-3xl mx-auto">
            <h1 className="text-4xl font-bold mb-4 text-center underline like">Join PSYC Aerospace & Defence</h1>
            <p className="text-center">
              At PSYC Aerospace, we strive to revolutionize aerospace technology. If you're passionate about innovation
              and want to make a significant impact, explore our career opportunities and join our dynamic team.
            </p>
            <div className="text-center mt-6 hover:border-6 hover:border-black">
            <Link to="https://forms.gle/PSnx1UhYtCfWhaLQ6">
  <button className="bg-gray-700 text-white px-6 py-3 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 font-semibold">
    Explore Opportunities
  </button>
</Link>

            </div>
          </div>
        </div>
    <Footer />
      <Footer_for_all />
    </div>
  );
};

export default FacultyGrid;
