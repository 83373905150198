import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const KnowledgePartners1 = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % carouselImages.length);
  };
  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + carouselImages.length) % carouselImages.length);
  };
  const carouselImages = [
    "/Videos/mysorezoo1.jpg",
    "/Videos/mysorezoo2.avif",
    "/Videos/mysorezoo3.jpg",
    "/Videos/mysorezoo4.png"
  ];
  const keyPersons = [
    // { name: "Coming Soon", role: "Head of Wildlife dept", image: "/Videos/placeholder.jpeg" },
    // { name: "Coming Soon", role: "Senior Researcher at Wildlife Institute", image: "/Videos/placeholder.jpeg" },
    // { name: "Coming Soon", role: "Head of Conservation Programs", image: "/Videos/placeholder.jpeg" }
  ];

  return (
    <div className="container mx-auto p-4 bg-black">
      {/* Flexbox for the icon and text */}
      <h2 className="text-3xl font-bold m-6 text-yellow-600">
        <div className="flex items-center">
          <ChevronRight className="text-yellow-600 mr-2" />
          Knowledge Partners:
        </div>
      </h2>
      
      <div className="flex flex-col md:flex-row gap-6">
        {/* Left section */}
        <div className="md:w-1/2 flex flex-col items-center">
          <a href="https://mysuruzoo.info/">
            <img
              src="/Videos/kp1.jpeg"
              alt="University Logo"
              className="w-96 h-96 mb-4 rounded-full border-4 border-yellow-600 shadow-lg"
            />
          </a>
          <h3 className="text-xl font-semibold text-white">Sri Chamarajendra Zoological Gardens (Mysuru Zoo)</h3>
        </div>
  
        {/* Right section */}
        <div className="md:w-1/2 relative">
          {/* Carousel */}
          <div className="overflow-hidden rounded-lg border-4 border-yellow-600 shadow-lg">
            <div className="flex transition-transform duration-300 ease-in-out" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
              {carouselImages.map((src, index) => (
                <img key={index} src={src} alt={`Slide ${index + 1}`} className="w-full flex-shrink-0" />
              ))}
            </div>
          </div>
          <button
            onClick={prevSlide}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-75 p-2 rounded-full shadow-lg text-yellow-600 hover:text-white hover:bg-yellow-600"
          >
            <ChevronLeft size={24} />
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-75 p-2 rounded-full shadow-lg text-yellow-600 hover:text-white hover:bg-yellow-600"
          >
            <ChevronRight size={24} />
          </button>
        </div>
      </div>
  
      {/* Key Persons section */}
      {/* <div className="flex items-center text-3xl text-blue-800">
          <ChevronRight className="text-blue-800 mr-2" />
          Key Persons:
        </div> */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {keyPersons.map((person, index) => (
          <div key={index} className="text-center">
            <img
              src={person.image}
              alt={person.name}
              className="w-32 h-32 bg-black rounded-full mx-auto mb-2 border-4 border-yellow-600 shadow-lg"
            />
            <h3 className="font-semibold text-yellow-600">{person.name}</h3>
            <p className="text-sm text-gray-300">{person.role}</p>
          </div>
        ))}
      </div>
    </div>
  );
};  
export default KnowledgePartners1;