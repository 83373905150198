import React, { useState, useEffect } from 'react';
import { Card, CardContent } from './Card';
import { Separator } from './Separator';
import { ChevronRight, Lightbulb, Cpu, LineChart, Trophy, Scale, BarChart3, Check, Layers } from 'lucide-react';
import { Link } from 'react-router-dom';
import Header_for_all from './Header_for_all';
import KnowledgePartners2 from './KnowledgePartners2';
import KnowledgePartners1 from './KnowledggePartners1';
import Footer_for_all from './Footer_for_all';



// const KnowledgePartners = () => {
//   const knowledgePartners = [
//     {
//       image: '/Videos/kp1.jpeg',
//       personImage: '/Videos/person1.jpeg',
//       name: 'Partner Organization 1',
//       description: 'Collaborating on advanced research initiatives',
//       link: 'https://mysuruzoo.info/',
//     },
//     {
//       image: '/Videos/kp2.png',
//       personImage: '/Videos/WildVision.jpg',
//       name: 'Partner Organization 2',
//       description: 'Joint programs in emerging technologies',
//       link: 'https://wildvisionai.in/',
//     },
//   ];

//   return (
//     <section className="mb-16">
//       <h2 className="text-3xl font-semibold m-6 text-blue-800 flex items-center">
//         <ChevronRight className="mr-2" />
//         Knowledge Partners:
//       </h2>
//       <div className="flex flex-col space-y-8">
//         {knowledgePartners.map((partner, index) => (
//           <div key={index} className="flex items-center space-x-8 bg-white shadow-lg p-6 rounded-lg hover:shadow-xl transition-shadow duration-300">
//             <a href={partner.link} target="_blank" rel="noopener noreferrer">
//               <img
//                 src={partner.image}
//                 alt={partner.name}
//                 className="w-60 h-60 rounded-full object-cover"
//               />
//             </a>
//             <div className="flex-grow flex items-center justify-between">
//               <div>
//                 <h3 className="text-xl font-semibold text-blue-900 px-60">{partner.name}</h3>
//                 <p className="text-gray-600 px-60">{partner.description}</p>
//               </div>
//               <img
//                 src={partner.personImage}
//                 alt={`Person from ${partner.name}`}
//                 className="w-60 h-60 rounded-full object-cover"
//               />
//             </div>
//           </div>
//         ))}
//       </div>
//     </section>
//   );
// };




const bgClasses = 'bg-black text-white p-6';
const textClasses = 'text-white';
const titleClasse = 'text-2xl font-bold text-gold';
const linkClasses = 'text-white hover:text-gold';
const borderClasses = 'border-white hover:border-gold';


// const images = ['/Videos/DSU1.png', '/Videos/DSU2.jpg', '/Videos/DSU3.jpg', '/Videos/DSU4.jpg'];


const ImageSlider = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = ['/Videos/DSU1.png', '/Videos/DSU2.jpg', '/Videos/DSU3.jpg', '/Videos/DSU4.jpg'];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 1500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-black">
      <img
        className="object-contain items-center rounded-3xl border-4 border-gold w-70 h-70 md:h-70"
        src={images[currentImage]}
        alt={`Image ${currentImage + 1}`}
      />
    </div>
  );
};


const Footer = () => {
  return (
    <div className={bgClasses}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <FooterItem title="About PSYC" content="Passive Surveillance Yielding Control - Innovating for a safer future." />
        <FooterItem title="Contact Us" content={<ContactContent />} />
        <FooterItem title="Follow Us" content={<FollowContent />} />
      </div>
      <div className="text-muted-foreground text-center mt-4">&copy; 2024 PSYC. All rights reserved.</div>
    </div>
  )
}

const FooterItem = ({ title, content }) => {
  return (
    <div className={textClasses}>
      <h2 className={titleClasse}>{title}</h2>
      <p>{content}</p>
    </div>
  )
}

const ContactContent = () => {
  return (
    <>
      <p>
        Email:{' '}
        <a href="mailto:psycdefence@gmail.com" className={linkClasses}>
          psycdefence@gmail.com
        </a>
      </p>
      <p>
        Phone:{' '}
        <a href="tel:+918861540565" className={linkClasses}>
          +91 88615 40655
        </a>
      </p>
    </>
  )
}

const FollowContent = () => {
  return (
    <>
      <a href="#" className={linkClasses}>
        Facebook
      </a>
      <br />
      <a href="#" className={linkClasses}>
        Twitter
      </a>
      <br />
      <a href="#" className={linkClasses}>
        LinkedIn
      </a>
    </>
  )
};




const Partners = () => {

  const Benefits = () => {
    const benefitsData = [
      { icon: <Lightbulb />, title: "Innovative Wildlife Solutions", description: "Darting drones provide a precise, non-invasive method for safer, stress-free elephant management, enhancing wildlife conservation efforts." },
      { icon: <Cpu />, title: "Conservation Impact", description: "Our technology enhances wildlife conservation by enabling safe, remote monitoring and management. It also offers valuable data collection and research opportunities, contributing to informed conservation strategies." },
      { icon: <LineChart />, title: "Market Potential", description: "The global demand for humane wildlife management is rising, with potential applications extending to other large mammals like rhinos and bison, ensuring safer interactions and conservation efforts worldwide." },
      { icon: <Trophy />, title: "Technological Leadership", description: "We are at the forefront of UAV technology in wildlife management, pioneering innovative solutions that ensure precision and safety in tranquilization efforts." },
      { icon: <Scale />, title: "Compliance with Regulations", description: "Our technology is fully compliant with wildlife protection laws and adheres to all drone regulations, ensuring ethical and legal operation in every deployment." },
      { icon: <BarChart3 />, title: "Scalability", description: "Our scalable solution adapts to different regions and species, customizing drones for diverse wildlife management challenges, ensuring effective conservation and conflict mitigation across global environments and various animal types." },
      { icon: <Check />, title: "Atmanirbhar Bharat", description: "Partnering with us supports the Make in India initiative, driving self-reliance in defense and conservation technology." },
      { icon: <Layers />, title: "Cross-Sector Applications", description: "Our technology has versatile applications beyond wildlife management, including disaster response for aerial surveillance and emergency assessment, and agricultural monitoring for crop health and precision farming." },
    ];
  
    return (
      <div className="py-20 px-4 bg-black">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-extrabold text-white underline mb-16 text-center">
            Why Partner with Us?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {benefitsData.map((benefit, index) => (
              <div
                key={index}
                className={`bg-gray-900 rounded-lg shadow-2xl overflow-hidden transform transition-transform duration-300 ease-in-out hover:scale-105 ${borderClasses} group`}
              >
                <div className="p-6 flex flex-col items-center">
                  <div className="text-4xl mb-4 text-white group-hover:text-yellow-600 transition-colors duration-300">
                    {benefit.icon}
                  </div>
                  <h3 className="text-xl font-semibold mb-3 text-white group-hover:text-yellow-600 transition-colors duration-300">
                    {benefit.title}
                  </h3>
                  <p className="text-gray-300 text-sm font-bold leading-relaxed group-hover:text-gray-200 transition-colors duration-300">
                    {benefit.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-16 text-center">
            <blockquote className="text-2xl font-semibold italic text-gray-100 border-l-4 border-yellow-600 pl-4">
              "Every moment we delay, another life—be it human or elephant—faces Death... Our urgency today can save countless lives tomorrow."
            </blockquote>
          </div>
        </div>
      </div>
    );
  };
  const partners = [
    { name: 'Dr. Sridhar SK', designation: "Dept of Computer Science & Engineering Dayananda Sagar University", image: '/Videos/Sridhar SK.png' },
    { name: 'Dr. Prasanth', designation: 'Dept of Aerospace Engineering Dayananda Sagar University', image: '/Videos/prashant.jpeg' },
    // { name: 'Dr. Nagaraja', designation: 'HOD, Dept of Aerospace Engineering Dayananda Sagar University', image: '/Videos/nagaraj.jpeg' },
  ];

  const DSULocation = React.memo(() => (
    <div className="max-w-lg mx-auto p-4 sm:p-8 bg-black shadow-xl rounded-lg mt-4 sm:mt-8 border-2 border-gold">
      <h2 className="text-2xl font-bold text-center text-yellow-600 border-b-2 border-gold pb-2">
        Pre-Incubated at DSU
      </h2>
      <div className="text-center mb-4 sm:mb-6">
        <p className="text-white font-semibold">Dayananda Sagar University (DSU) - Main Campus</p>
        <p className="text-gray-300">Devarakaggalahalli, Harohalli Kanakapura Road,</p>
        <p className="text-gray-300">Ramanagara District, Karnataka 562112</p>
      </div>
      <div className="w-full h-48 sm:h-64 border-4 border-gold rounded-lg overflow-hidden">
        <iframe
          key="google-map"
          src="https://maps.google.com/maps?q=Dayananda%20Sagar%20University,%20Devarakaggalahalli,%20Harohalli%20Kanakapura%20Road,%20Dt,%20Ramanagara,%20Karnataka%20562112&t=&z=13&ie=UTF8&iwloc=&output=embed"
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
    </div>
  ));  
  const ContactForm = () => {
    return (
      <div className="container mx-auto px-4 sm:px-8 py-8 sm:py-16 bg-black rounded-lg shadow-2xl border-2 border-gold mt-8 sm:mt-12 flex flex-wrap">
        <div className="w-full lg:w-1/2 lg:pr-8">
          <h2 className="text-3xl font-bold text-yellow-600 mb-6 sm:mb-8 border-b-2 text-center border-gold pb-2">
            Let's have a deal...?
          </h2>
          <form action="https://formspree.io/f/xldrynya" method="POST" className="max-w-lg mx-auto">
            <div className="mb-6 text-center">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="name">Full Name</label>
              <input
                className="shadow-sm appearance-none border-2 rounded-md w-full py-2 sm:py-3 px-3 sm:px-4 text-black leading-tight focus:outline-none focus:ring-2 focus:ring-gold"
                type="text"
                name="name"
                id="name"
                required
              />
            </div>
            <div className="mb-6 text-center">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="email">Email Address</label>
              <input
                className="shadow-sm appearance-none border-2 rounded-md w-full py-2 sm:py-3 px-3 sm:px-4 text-black leading-tight focus:outline-none focus:ring-2 focus:ring-gold"
                type="email"
                name="email"
                id="email"
                required
              />
            </div>
            <div className="mb-6 text-center">
              <label className="block text-white text-sm font-bold mb-2" htmlFor="message">Message</label>
              <textarea
                className="shadow-sm appearance-none border-2 rounded-md w-full py-2 sm:py-3 px-3 sm:px-4 text-black leading-tight focus:outline-none focus:ring-2 focus:ring-gold"
                name="message"
                id="message"
                rows="4"
                required
              ></textarea>
            </div>
            <div className="text-center">
              <button
                className="bg-gold hover:bg-white text-black font-bold py-2 sm:py-3 px-6 rounded-md focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
                type="submit"
              >
                Submit Inquiry
              </button>
            </div>
          </form>
        </div>
        <div className="w-full mt-6 lg:w-1/2 lg:mt-0">
          <DSULocation />
        </div>
      </div>
    );
  };
  
  return (
    <div className="bg-black min-h-screen">
      <Header_for_all />
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-2 text-gold text-center underline">Partnerships</h1>
        <Separator className="mb-8" />
        {/* Sections */}
        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-yellow-600 flex items-center">
            <ChevronRight className="mr-2" />
            Institutional Partners:
          </h2>
          <div className="bg-black shadow-lg rounded-lg overflow-hidden">
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/2 p-8 flex items-center justify-center bg-black">
                <img src="/Videos/DSU_logo.png" alt="College Logo" className="max-w-full rounded-full h-auto" />
              </div>
              <div className="w-full md:w-1/2 relative h-[280px] rounded-3xl overflow-hidden">
                <ImageSlider />
              </div>
            </div>
          </div>
        </section>
          <section>
            <h2 className="text-3xl font-semibold mb-6 text-blue-800 flex items-center">
              <ChevronRight className="mr-2" />
              Key Persons:
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {partners.map((partner, index) => (
                <Card key={index}>
                  <div className="flex flex-col items-center">
                    <div className="w-36 h-36 overflow-hidden rounded-full m-4">
                      <img src={partner.image} alt={partner.name} className="object-cover w-full h-full" />
                    </div>
                    <CardContent className="text-center">
                      <h3 className="text-xl font-semibold mb-2 text-blue-900">{partner.name}</h3>
                      <p className="text-gray-700">{partner.designation}</p>
                    </CardContent>
                  </div>
                </Card>
              ))}
            </div>
          </section>
          <KnowledgePartners1 />
          <KnowledgePartners2 />
          <Benefits />
          <ContactForm />
        </div>
      <Footer />
      <Footer_for_all />
    </div>
  );
};
export default Partners;